<template>
  <div>
    <div class="container" style="font-size: 1.5625vw;font-weight: 500;">产品安装视频下载</div>
    <div class="top">
      <p>
        <video width="368" controls>
          <source src="../../../assets/mock/serve/产品宣传.mp4" type="video/mp4" />
        </video>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  margin: 4rem 0;
  text-align: center;
  .top {
    p { 
      margin: 0.625rem;
    }
  }
}
</style>
